<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-icon>fas fa-filter</v-icon>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <!--#region RegDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateFrom.value"
                                        @input="regDateFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region RegDateTo  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateTo.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateTo.value"
                                        @input="regDateToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->
                    
                    <!--#region ValidityFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.ValidityFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="validityFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.ValidityFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.ValidityFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.ValidityFrom.value"
                                        @input="validityFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region ValidityTo  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.ValidityTo.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="validityToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.ValidityTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.ValidityTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.ValidityTo.value"
                                        @input="validityToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->
    
                    <!--#region PersonCategory  -->
                    <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.PersonCategory.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="personCategoryValues"
                                    v-model="val.PersonCategory.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.PersonCategory.value = []"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row> 
                    <!--#endregion -->

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="save" 
                        v-if="isFormValid"
                    >
                        {{$t("Применить_фильтр")}}                    
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import sys from '@/services/system';

export default {
    name: "ContractExFilterDlg",
    props: {
        value:
        {
            type: Object,
            default: null
        }
    },
    asyncComputed: {
        nomenclatures : {
            async get() {
                if (this.nomenclatureSearch) {
                    let selectedIds = this.val.NomenclatureId.value.length ? this.val.NomenclatureId.value.map(i => i.id) : [];

                    return (await this.$store.dispatch('references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Value: `${x[1]} ${x[2]}`, type: parseInt(x[3]) }))
                        .filter(x => x.type === 3 && sys.checkSearch(x.Value, this.nomenclatureSearch) || selectedIds.includes(x.id));
                }
                else
                    return (await this.$store.dispatch('references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Value: `${x[1]} ${x[2]}`, type: parseInt(x[3]) }))
                        .filter(x => x.type === 3);
            },
            default: [],
            watch: ['nomenclatureSearch']
        }
    },
    data() {
        return {
            title: "Фильтр",
            isFormValid: true,
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            val: JSON.parse(JSON.stringify(this.value)),
            
            regDateFromMenu: false,
            regDateToMenu: false,
            validityFromMenu: false,
            validityToMenu: false,

            personCategoryValues: [
                { id: 1, Value: this.$t("Физическое_лицо") },
                { id: 2, Value: this.$t("Индивидуальный_предприниматель") },
                { id: 3, Value: this.$t("Юридическое_лицо") },
                { id: 4, Value: this.$t("ГКП") },
                { id: 5, Value: this.$t("Акционерное_общество") },
                { id: 6, Value: this.$t("Товарищество_с_ограниченной_ответственностью") },
            ],
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    methods: {        
        ...mapActions('references', ['getReference', 'getWorkplaces']),
        ...mapActions(['setOverlayVisible']),
        async initialize() {

        },
        async open(options = {})
        {
            this.val = JSON.parse(JSON.stringify(this.value)),
            this.visible = true;
            this.options = Object.assign(this.options, options);

            this.setOverlayVisible({ visible: true });            
            this.initialize();
            this.setOverlayVisible({ visible: false });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });                
        },
        save() {
            this.visible = false;
            this.$emit('input', this.val);
            this.resolve();
        },
        handleDate(source) {
            var formatedDate = sys.dateFormat(source, 'DD.MM.YYYY');
            return formatedDate ?? "";
        },
    }
}
</script>