<template>
    <div v-if="dataSource" class="p-relative">
        
        <Toolbar
            :isList="true"
            :hideCreateButton="hideCreate"
            :menu="menu"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <div class="top-btns-bar-right-section">
                <v-icon
                    v-if="Object.keys(extendedTableFilter).length !== 0"
                    class="extend-filter"
                    v-tooltip.left-center="$t('Расширенный_поиск')"
                    left
                    @click="openExtendedFilterDialog"
                >
                    mdi-filter
                </v-icon>

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>

            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
            :show-collection="false"
        >
            <ExtendedFilterPanel 
                v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)"
                v-bind:value.sync="extendedTableFilter"
            />
        </FilterPanel>

        <component
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

        <ContractExFilterDlg 
            v-if="collection === 'Contracts'"
            v-model="extendedTableFilter" 
            ref="ContractExFilterDlgRef" 
        />

    </div>
</template>

<script>
import _ from 'lodash';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import ExtendedFilterPanel from '@/components/ExtendedFilterPanel.vue';
import ContractExFilterDlg from '@/components/dialogs/extended-filters/correspondence/contract.vue';
import Contracts from './lists/Contracts.vue';

export default {
    name: "ContractsProxy",
    components: {
        Toolbar,
        FilterPanel,
        ExtendedFilterPanel,
        ContractExFilterDlg,
        Contracts
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null
        }
    },
    computed: {
        hideCreate() {
            return true;
        },
        menu() {
            return this.dataSource.Data.Menu;
        },
        collection: {
            get: function() {
                return this.$store.getters['contractsProxy/getCollection']
            },
            set: function(newValue) {
                this.$store.commit('contractsProxy/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['contractsProxy/getStatus'];
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('contractsProxy/PUSH_STATUS', { collection: this.collection, value: newValue } );

                    if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                        this.$refs.currentComponent.resetPage();
                    }
                }
            }
        },
        search: {
            get: function() {
                return this.$store.getters['contractsProxy/getSearch']
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }
                this.$store.commit('contractsProxy/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
        extendedTableFilter: {
            get: function() {
                return this.$store.getters['contractsProxy/getExtendedFilter'];
            },
            set: function(newValue) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }   
                if (newValue) {
                    this.$store.commit('contractsProxy/PUSH_EXTENDED_FILTER',
                    { 
                        collection: this.collection,
                        value: newValue
                    } );
                }
            }
        },
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onToolbarClick(event, btn) {
            this[btn.Action](btn);
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        },
        async Export() {
            let exportURI = null;
            let filter = this.$refs.currentComponent.getTableFilterObject();
            delete filter.Limit;
            delete filter.Offset;
            if (this.collection == "Contracts"){
                exportURI = `api/csc/requests/contractsexport?filter=${JSON.stringify(filter)}`;
            }

            if (process.env.NODE_ENV === "production")
                exportURI += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;

            this.$eventBus.$emit('on-get-report', {url: exportURI, text: `Экспорт списка договоров от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}`, type: 'Xls' });
        },
        async openExtendedFilterDialog() {
            try
            {
                switch (this.collection)
                {
                    case 'Contracts':
                        await this.$refs.ContractExFilterDlgRef.open();
                        break;

                    default: break;
                }
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }
        },
        async loadData() {
            this.setOverlayVisible({ visible: true });

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let type = '';

            switch (this.collection)
            {
                case 'Contracts':
                    type = 'Documents.Correspondence.Contracts'; break;

                default:
                    type = 'Documents.Correspondence.Contracts'; break;
            }

            let response = await httpAPI({
                url: `api/actions/collection?type=${type}`,
                method: 'GET'
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.payload;
            }

            this.setOverlayVisible({ visible: false });
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `api/csc/requests/contract/filter?collection=${this.collection}&configuration=32`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (filterResponse)
                this.filterDataSource = filterResponse.data.payload;
        },
    },
    async created() {
        await this.loadData();
    },
    watch: {
        collection() {
            this.loadData();
        }
    },
}
</script>